/* Search container styles */
.search-container {
  position: relative;
  max-width: 1000px;
  width: 100%;
}

.search-input {
  width: 100%;
  min-width: 500px;
  height: 45px;
  padding-right: 50px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 20px;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.search-icon {
  width: 20px;
  height: 20px;
}

.search-icon-mobile {
  width: 18px;
  height: 18px;
}

/* Mobile search styles */
.mobile-search-container {
  padding: 0.75rem 1rem;
  border-top: 1px solid #e9ecef;
}

.mobile-search-input {
  width: 100%;
  height: 45px;
  padding-right: 50px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.mobile-search-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 20px;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-search-toggle {
  padding: 0.25rem;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Focus states with enhanced glow effect */
.search-input:focus,
.mobile-search-input:focus {
  outline: none;
  border-color: #0ea5e9;
  box-shadow: 0 0 0 4px rgba(14, 165, 233, 0.15);
  background-color: #ffffff;
}

/* Hover states */
.search-button:hover,
.mobile-search-button:hover,
.mobile-search-toggle:hover {
  color: #0ea5e9;
}

/* Button reset for better accessibility */
.search-button,
.mobile-search-button,
.mobile-search-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Placeholder color consistency */
.search-input::placeholder,
.mobile-search-input::placeholder {
  color: #9ca3af;
  opacity: 1;
}

/* Hover state for input */
.search-input:hover,
.mobile-search-input:hover {
  border-color: rgba(14, 165, 233, 0.5);
  box-shadow: 0 0 0 2px rgba(14, 165, 233, 0.1);
}
