.banner-upload-container {
  width: 100%;
  margin-bottom: 1rem;
}

.dropzone-area {
  border-radius: 0.5rem;
  text-align: center;
  transition: all 0.3s ease;
  background-color: rgba(var(--vz-dark-rgb), 0.18) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-height: 0px !important;
  padding: 0.1rem;
}

.dropzone-area:hover:not(.disabled) {
  border-color: #0d6efd;
  background: #f8f9fa;
}

.dropzone-area.drag-active {
  border-color: #0d6efd;
  background: #f8f9fa;
  animation: pulse 1.5s infinite;
}

.dropzone-area.disabled {
  background: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
}

.dropzone-area.has-error {
  border-color: #dc3545;
}

.dropzone-area.is-loading {
  cursor: wait;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.dropzone-icon {
  font-size: 3rem;
  color: #6c757d;
}

.dropzone-text {
  margin: 0;
  color: #212529;
  font-size: 1rem;
}

.dropzone-hint {
  color: #6c757d;
  font-size: 0.875rem;
  margin: 0;
}

.preview-wrapper {
  position: relative;
  width: 100%;
  max-width: 1188px;
  margin: 0 auto;
}

.preview-image {
  width: 100%;
  height: auto;
  max-height: 173px;
  object-fit: cover;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.remove-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #dc3545;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0.9;
  padding: 0;
}

.remove-button:hover {
  opacity: 1;
  transform: scale(1.05);
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.loading-indicator p {
  margin: 0;
  color: #6c757d;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.format-info {
  color: #6c757d;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.005);
  }

  100% {
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dropzone-area {
    min-height: 170px;
  }

  .dropzone-icon {
    font-size: 2rem;
  }

  .dropzone-text {
    font-size: 0.875rem;
  }

  .dropzone-hint {
    font-size: 0.75rem;
  }
}

/* Accessibility focus styles */
.dropzone-area:focus-within {
  outline: 2px solid #0d6efd;
  outline-offset: 2px;
}

/* Dark mode support if needed */
@media (prefers-color-scheme: dark) {
  .dropzone-area {
    background: #1a1a1a;
    border-color: #404040;
  }

  .dropzone-hint,
  .format-info {
    color: #a0a0a0;
  }

  .dropzone-area:hover:not(.disabled) {
    background: #262626;
  }
}
