/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
/* Common styles for all screen sizes */
.auth-one-bg {
  background-image: url(https://cdn.mywanderlinx.com/assets/images/Login_wander.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.header-branding {
  background-color: #3f5164 !important;
}

body {
  margin: 0 !important;
  font-family: var(--vz-body-font-family) !important;
  font-size: var(--vz-body-font-size) !important;
  font-weight: var(--vz-body-font-weight) !important;
  line-height: var(--vz-body-line-height) !important;
  color: var(--vz-body-color) !important;
  text-align: var(--vz-body-text-align) !important;
  background-color: #f3f3f9 !important;
  -webkit-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: transparent !important;
  touch-action: pan-y;
}

.linear-gradient {
  background: linear-gradient(326deg, rgb(24, 153, 148) 50%, rgb(43, 193, 155));
}

.otp-option-phone {
  padding: 12px !important;
  border: 1px solid #ced4da;
  border-right: 0;
}

.otp-option-email {
  padding: 12px !important;
  border: 1px solid #ced4da;
}

.modal-loader .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.label-cursor {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.h-27-w-31 {
  width: 31px !important;
  height: 27px !important;
}

.m-l-11 {
  margin-left: 11px;
}

.gap-1-5rem {
  gap: 1.5rem !important;
}

.body-default-color {
  background-color: #f0f2f58f;
}

.font-600 {
  font-weight: 600;
}

.skeleton-wrapper {
  display: flex;
}

.w-100px {
  width: 100px !important;
}

.h-100px {
  height: 100px !important;
}

.padding-0 {
  padding: 0 !important;
}

/* Add this to your CSS file */
.toast-fade-enter,
.toast-fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.toast-fade-enter-active,
.toast-fade-exit {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.option-vendor {
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  font-size: 14px;
  padding-top: 10px;
}

.pl-50px {
  padding-right: 50px !important;
}

.option-select-vendor {
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  font-size: 14px;
  padding-top: 10px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pp-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.form-floating > .form-select {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.custom-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.bl-none {
  border-left: none !important;
}

.br-0 {
  border-radius: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.8rem;
}

.r-0 {
  right: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: var(--vz-heading-color) !important;
  font-family: Poppins, sans-serif !important;
}

.mt-5px {
  margin-top: 5px;
}

.h5-fs {
  font-size: 1.015625rem !important;
}

.mb-p5 {
  margin-bottom: 0.5rem !important;
}

.passenger-form-hr {
  border: 0;
  border-top: 1px solid #7c7c81 !important;
  margin-bottom: 20px;
  margin-top: 20px;
}

.itinerary-font-size {
  font-size: 0.8125rem;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb-point-5-rem {
  margin-bottom: 0.5rem !important;
}

.ml-p-5 {
  margin-left: 0.5rem;
}

.line-height-500 {
  line-height: 1.2 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.color-0ab39c {
  color: #0ab39c !important;
}

.font-700 {
  font-weight: 700 !important;
}

.history-picker .rs-picker-toggle.rs-btn-lg {
  font-size: 14px !important;
  line-height: 22px !important;
  --vz-bg-opacity: 1 !important;
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
}
.history-picker .rs-btn-lg {
  padding-bottom: 8px !important;
  padding-top: 7px !important;
}
.history-picker .rs-picker-toggle:focus .rs-btn-lg:focus {
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.stop_middle_layover {
  background: #ffffff;
  width: 30px;
  height: 9px;
  position: absolute;
  top: -4px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  left: 44%;
}

.font-17 {
  font-size: 16px !important;
}

.float-right {
  float: right !important;
}

.badge-warning {
  color: #fff;
  font-size: 10px;
  background-color: #ff6f00;
  font-weight: 300;
  border-radius: 2px;
}

.seats-left {
  position: absolute;
  right: 67px;
  top: 10px;
  z-index: 1;
}

.departure-date-picker .rs-picker-toggle.rs-btn-lg {
  height: 55px !important;
  font-size: 1.015625rem !important;
  padding: 17px !important;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.departure-date-picker > div > div > div > span.rs-picker-toggle-value {
  color: var(--vz-body-color) !important;
}

.arrival-date-picker .rs-picker-toggle.rs-btn-lg {
  height: 55px !important;
  font-size: 1.015625rem !important;
  padding: 17px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.arrival-date-picker .rs-btn-disabled {
  background-color: #d5d5d9 !important;
}

.arrival-date-picker > div > div > div > span.rs-picker-toggle-value {
  color: var(--vz-body-color) !important;
}

.payment-list-card {
  border: 1px solid #dadfe6;
  border-radius: 2px;
  width: 40px;
  height: 27px;
}

.provider.nav-tabs {
  list-style-type: none;
  padding: 0;
  width: 200px;
}

.provider.nav-tabs li {
  border-radius: 2px;
  margin: 0;
  padding: 12px 12px;
  background-color: #f1f1f1;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  margin-bottom: 5px;
}

.provider.nav-tabs li.active {
  background-color: #0ab39c;
  color: white;
  border: none;
}

.provider.tab-content {
  padding: 10px;
  border: 1px solid #ddd;
  margin-left: 220px;
}

.provider.tab-pane {
  display: none;
}

.provider.tab-pane.active {
  display: block;
}

.wid-100 {
  width: 100%;
}

.h-1em {
  height: 1em;
}

.card-img-fit {
  width: 100%; /* make the image take the full width of its container */
  height: auto; /* maintain the aspect ratio of the image */
  object-fit: cover; /* this will cover the area of the element without stretching the image */
  border-radius: 0.25rem; /* optional, for rounded corners if desired */
}

@media only screen and (max-width: 650px) {
  .provider-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100% !important;
  }

  .seats-left {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
  }

  .flight-fare {
    margin-top: 8px;
    margin-right: -50px;
  }

  .select_flight_mbl {
    background-color: #0ab39c !important;
    color: #ffffff !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-right: -70px !important;
  }
}

.custom-time .rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
  outline: none !important;
  border-color: none !important;
}

.custom-time .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: none !important;
}

.custom-time > .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

.custom-time > .rs-btn-default {
  z-index: 0 !important;
}

.account-profile-img {
  height: 4.5rem !important;
  width: 4.5rem !important;
}

.gap-1rem {
  gap: 1rem !important;
}

.icon-font-1-3em {
  font-size: 1.3em;
}

.cover-fit {
  object-fit: cover !important;
  border: 1px solid #c0c0c0 !important;
}

.to-uppercase {
  text-transform: uppercase;
}

.uppercase-text input {
  text-transform: uppercase !important;
}

.font-15px {
  font-size: 15px;
}

.text-red {
  color: #f44336;
}

.drop-down-active {
  color: #fff !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-10 {
  z-index: 1000;
}

.aspect-ratio {
  --vz-aspect-ratio: 50%;
}

.aspect-video {
  position: relative;
  overflow: hidden;
  /* Ensure the aspect ratio container is appropriately sized */
  width: 560px; /* Adjust as needed */
  height: 315px; /* Adjust as needed */
}

.video-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video-element {
  width: 100%;
  height: 100%;
  display: block;
}

.overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em; /* Adjust size as needed */
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Prevent the button from capturing mouse events */
}

.video-container:hover .overlay-button {
  opacity: 1; /* Button appears on hover */
}

.video-controls {
  display: flex;
  justify-content: center;
  padding: 12px;
  background: rgba(0, 0, 0, 0.5);
}

.seek-slider[type="range"] {
  -webkit-appearance: none; /* Override default appearance */
  appearance: none;
  width: 100%;
  height: 6px; /* Adjust thickness */
  background: #ddd; /* Slider background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Partial transparency */
  -webkit-transition: 0.2s; /* Transition for hover effect */
  transition: opacity 0.2s;
  border-radius: 4px;
}

.seek-slider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default appearance */
  appearance: none;
  width: 15px; /* Adjust thumb width */
  height: 15px; /* Adjust thumb height */
  background: #ffffff; /* Thumb color */
  cursor: pointer; /* Re-style cursor to indicate slider */
  border-radius: 50%; /* Circular thumb */
}

.seek-slider[type="range"]:hover {
  opacity: 1; /* Fully opaque on hover */
}

.header-flight-search {
  background: #fff !important;
  padding: 10px !important;
  border-radius: 2px !important;
  border: 1px Solid #ddd !important;
  box-shadow: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.t-capitalize {
  text-transform: capitalize;
}

.h-form {
  height: 45px;
}

.h-input-form {
  height: 39px;
}

.h-50px {
  height: 50px !important;
}

@media only screen and (max-width: 650px) {
  .h-form {
    height: 50px;
  }

  .h-input-form {
    height: 44px;
  }

  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50% !important;
  }
  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100% !important;
  }
  .mbl-swt {
    left: 87% !important;
    top: 37px !important;
    z-index: 5;
    transform: translate(-50%, 8px) rotate(90deg) !important;
  }
}

.bnt-cstm {
  width: 50%;
  border-radius: 50px;
  text-align: center;
}

.avail-seat {
  position: absolute !important;
  top: -5px !important;
  right: -10px !important;
  border-radius: 0px 3px 0px 5px !important;
}

.bg-grey {
  background: #f2f2f2 !important;
}

.icon-rotate-90-deg {
  background: linear-gradient(90deg, #ebeef1, rgb(255 255 255 / 0%));
}

.pwd-upload {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passenger-d-onward {
  padding: 0 4px !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
}

.bg-gradient {
  background: linear-gradient(
    90deg,
    #dadfe64f 31.76%,
    rgb(228 228 228 / 23%) 87.8%
  );
}

.bg-arrival-gradientt {
  background: linear-gradient(
    90deg,
    #ececec 31.76%,
    rgba(255, 255, 255, 0) 87.8%
  );
}

.fs-12 {
  font-size: 12px;
}

.dividervertical::before {
  left: -5px;
  content: "";
  background: #dadfe6;
  width: 8px;
  height: 8px;
  position: absolute;
  display: flex;
  border-radius: 2px;
  top: 0;
}

.dividervertical::after {
  left: -5px;
  content: "";
  background: #dadfe6;
  width: 8px;
  height: 9px;
  position: absolute;
  display: flex;
  border-radius: 2px;
  bottom: 0px;
}

.dividervertical {
  position: absolute;
  border-left: 2px solid #dadfe6;
  height: 95px;
  top: 5px;
  left: 113px;
}

.dividerhorizontal {
  width: 100%;
  border: 1px solid #dadfe6;
  position: relative;
  margin: 3px;
}

.dividerhorizontal::after {
  content: "";
  background: #dadfe6;
  width: 9px;
  height: 8px;
  position: absolute;
  display: flex;
  border-radius: 2px;
  left: -5px;
  top: -5px;
}

.dividerhorizontal::before {
  content: "";
  background: #dadfe6;
  width: 10px;
  height: 9px;
  position: absolute;
  display: flex;
  border-radius: 2px;
  right: -5px;
  top: -5px;
}
.change-flight-btn {
  position: absolute;
  right: 0;
  font-size: 10px;
  text-decoration: none;
  outline: 1px solid #3264ff36;
  transition: 0.3s;
  display: inline-flex;
  border-radius: 11px;
  background: rgba(50, 100, 255, 0.08);
  line-height: 19px;
  padding-inline: 8px;
  color: #3264ff;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .w-20 {
    width: 20% !important;
  }
}

@media (max-width: 992px) {
  .height-scrollbar-small {
    height: 298px;
  }
  .height-scrollbar-large {
    height: 498px;
  }
}

@media (min-width: 993px) {
  .default-scrollbar-height {
    height: 498px;
  }
}

.flight-change-list {
  position: relative;
  z-index: 10;
  overflow-y: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.border-none-important {
  border: none !important;
}

@media (max-width: 768px) {
  .hotel-search-box {
    width: 100% !important;
  }
  .hotel-history-table > div:nth-child(1) > div {
    width: 700px !important;
  }
  .hotel-history-table > div:nth-child(1) > div > div {
    width: inherit !important;
  }
  .hotel-history-table
    > div:nth-child(1)
    > div
    > div
    > div:nth-child(1)
    > div
    > div {
    flex-grow: 0 !important;
  }
  .hotel-history-table
    > div:nth-child(1)
    > div
    > div
    > div:nth-child(2)
    > div
    > div {
    flex-grow: 0 !important;
  }
  .hide-on-mobile {
    display: none;
  }
}

.rs-picker-menu {
  z-index: 1100 !important;
}

.modal-backdrop {
  z-index: 1050 !important;
}

.modal {
  z-index: 1055 !important;
}

.creatable-design div {
  border-radius: 0;
}

.hotel-date-range > .rs-picker-toggle {
  height: 55px;
  border: 1px solid #ccc !important;
  color: hsl(0, 0%, 20%);
  border-radius: 0;
}

.hdr-rounded-start .rs-picker-toggle {
  border-top-left-radius: var(--vz-border-radius) !important;
  border-bottom-left-radius: var(--vz-border-radius) !important;
}

.hdr-rounded-end .rs-picker-toggle {
  border-top-right-radius: var(--vz-border-radius) !important;
  border-bottom-right-radius: var(--vz-border-radius) !important;
}

.hotel-date-range .rs-picker-toggle.rs-btn-lg {
  line-height: 36px;
}

.hotel-date-range > .rs-picker-toggle.rs-btn-lg > .rs-picker-toggle-value {
  color: hsl(0, 0%, 50%) !important;
}

.hotel-date-range
  .rs-picker-toggle
  .rs-stack
  .rs-stack-item
  .rs-picker-toggle-textbox {
  color: hsl(0, 0%, 50%) !important;
}

.hotel-date-range .rs-picker-toggle .rs-stack .rs-stack-item .rs-btn-close {
  top: 15px !important;
}

.hotel-date-range .rs-picker-toggle:hover,
.hotel-date-range .rs-picker-toggle:focus,
.hotel-date-range .rs-picker-toggle-active {
  box-shadow: none !important; /* Remove box-shadow */
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
  color: white; /* White font color */
  border: none;
  border-radius: 50%; /* Circular button */
  padding: 10px; /* Size of the button */
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease; /* Smooth hover transition */
}

.custom-prev:hover,
.custom-next:hover {
  background: rgba(255, 255, 255, 0.3); /* Brighter on hover */
  color: #f8f9fa; /* Light white on hover */
}

.custom-prev {
  left: 10px; /* Position to the left */
}

.custom-next {
  right: 10px; /* Position to the right */
}

/* Optional: Add larger icons */
.custom-prev svg,
.custom-next svg {
  font-size: 24px; /* Adjust icon size */
}

.swiper-button-prev,
.swiper-button-next {
  display: none; /* Hide default Swiper buttons */
}

.creatable-design div {
  border-radius: 0;
}

.creatable-design div {
  border-radius: 0;
}

.hotel-left-bordered .rs-picker-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 1px solid var(--vz-input-border) !important;
}

.hotel-right-bordered .rs-picker-toggle {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  border-right: 1px solid var(--vz-input-border) !important;
  border-top: 1px solid var(--vz-input-border) !important;
  border-bottom: 1px solid var(--vz-input-border) !important;
}

.hotel-datetime-picker .rs-picker-toggle {
  display: flex !important;
  justify-content: left;
  height: 45px;
  padding: 0.5rem 0.9rem !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
}

.hotel-datetime-picker
  .rs-picker-toggle
  .rs-stack
  > div:nth-child(2)
  .rs-picker-toggle-caret {
  margin-top: 4px;
}

.hotel-datetime-picker .rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
}

.hotel-datetime-picker .rs-stack .rs-stack-item .rs-picker-toggle-textbox {
  font-size: 16px !important;
  padding: 0.5rem 2rem 0.5rem 0.9rem !important;
}

.hotel-search-box {
  width: 35%;
}

.hotel-booking-cell {
  min-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-hotel-field {
  background-color: var(--vz-input-disabled-bg); /* Light gray background */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Make it less opaque */
  pointer-events: none; /* Disable all pointer events */
}

.hotel-img-important-height {
  height: 130px !important;
}
